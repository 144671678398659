@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@lyearn/style/dist/tailwind_extension.css';

/**
 * Note: added these styles from react-show-more-text/lib/ShowMoreText.css
 * as it failing in production build.
 * https://github.com/devzonetech/react-show-more-text/blob/master/src/ShowMoreText.css
 */
span.show-more-less-clickable {
  cursor: pointer;
  text-decoration: underline;
}

span.show-more-less-clickable:hover {
  text-decoration: none;
}
