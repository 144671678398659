html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
}

.transformToCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border-3 {
  border-width: 3px;
}

[data-reach-skip-nav-link] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

[data-reach-skip-nav-link]:focus {
  padding: 1.6rem;
  position: fixed;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: var(--bg-primary);
  box-shadow: rgb(100 153 255) 0px 0px 0px 1px;
  z-index: 100;
  width: auto;
  height: auto;
  clip: auto;
}

/* in dark mode elevated content should have different background color
because we use bg-bg-primary inside elements of a modal, applying linear-gradient(default behaviour of MUI) to the modal root will give inconsistent background color
so we override the primary background color of the modal root */
.darkMode .MuiPaper-root {
  --bg-primary: #2d2d2d;
}
